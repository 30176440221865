import React from 'react';
import { FaFilePdf } from 'react-icons/fa';
import Layout from '../components/Layout';
import Container from '../components/Container';
import { useStaticQuery, graphql } from 'gatsby';

const PressPage = () => {
  const pdf = useStaticQuery(graphql`
    query {
      old: file(relativePath: { eq: "ett_battre_skolvalssystem.pdf" }) {
        publicURL
        name
      }
      new: file(relativePath: { eq: "Policybrief_battreskolval.1.pdf" }) {
        publicURL
        name
      }
    }
  `);

  return (
    <Layout>
      <Container>
        <h1>Press</h1>

        <h3>Politiska reformer för ett bättre skolval</h3>
        <p>
          Mitt Skolval är ett företag grundat av några av Sveriges ledande
          skolvalsforskare. Vi hjälper kommuner och fristående skolor med att
          hantera, administrera och utveckla skolvalet. I detta dokument
          presenterar vi de juridiska hinder som finns för ett mer rättssäkert
          och likvärdigt skolval med anledning av regeringens proposition Ett
          mer likvärdigt skolval (2021/22:158), utifrån den teoretiska och
          praktiska kunskap vi tillägnat oss i detta arbete.
        </p>
        <a
          className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded inline-flex flex-row items-center mb-12"
          href={pdf.new.publicURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFilePdf className="mr-4" />
          <span>Hämta PDF</span>
        </a>

        <h3>Ett bättre skolvalssystem</h3>
        <span>Ett policydokument från Mitt Skolval</span>
        <p>
          Mitt Skolval är ett företag som grundats och drivs av några av
          Sveriges ledande skolvalsforskare. Vi arbetar runt om i kommuner med
          att designa och hantera skolval för grundskolan. Från både teori och
          praktik har vi samlat på oss kunskap om vad som gör ett skolval bra.
          Denna kunskap vill vi dela med oss av här, med anledning av
          utredningen om En mer likvärdig skola – minskad skolsegregation och
          förbättrad resurstilldelning, SOU 2020:28. Vi kommer fokusera på
          skolvalets utformning för elevplacering och därmed inte gå in på
          övriga aspekter av skolmarknaden, såsom finansiering, etableringsrätt
          eller andra styrningsfrågor.
        </p>
        <a
          className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded inline-flex flex-row items-center mb-12"
          href={pdf.old.publicURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFilePdf className="mr-4" />
          <span>Hämta PDF</span>
        </a>
      </Container>
    </Layout>
  );
};

export default PressPage;
